import Prestalib from "./prestalib";

function cache_load_cart(){
    let elem = document.querySelector('.blockcart.prestalib-cache');
    if(!elem) return ;
    console.log('PL: dom loaded, load cart');
    var refreshURL = elem.getAttribute('data-refresh-url');
    fetch(refreshURL).then(response => response.json()).then(json => {
        //console.log(json.preview);
        let preview = json.preview;

        var div = document.createElement('div');
        div.innerHTML = preview.trim();
        var cart = div.querySelector('.blockcart');
        document.querySelector('.blockcart').innerHTML = cart.innerHTML;
    });
}

document.addEventListener('DOMContentLoaded',function(){

    console.log("PS: init");
    prestalib = new Prestalib(prestalib);
    if(typeof prestashop !== 'object') {
        console.error("PS: prestashop object not loaded.");
        return false;
    }
    // var oldfunc = prestashop.blockcart.showModal;

    // cache_load_cart();
    /*prestashop.blockcart.showModal =  (modal)=> {
    
        // alert('ok');
        oldfunc(modal);
        prestashop.emit('showModal',{params: modal});
    };*/
    //     var $body = $('body');
    //     let elem = $body.append(modal);

    //     prestashop.emit('showModal',{html: modal,node: elem});

    //     $body.one('click', '#blockcart-modal', function (event) {
    //       if (event.target.id === 'blockcart-modal') {
    //         $(event.target).remove();
    //       }
    //     });
    //   };
});